<template>
  <div>
    <!-- <img
      style="
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        display: block;
        width: 100%;
        z-index: -1;
      "
      src="../assets/image/homepage.jpg"
    /> -->
    <headPage />
    <!-- 品质服务 -->
    <div class="box1" style="overflow: hidden">
      <div class="info">
        <p class="p1">以专业化为依托</p>
        <p class="p2">提供品质服务</p>
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="box2" style="overflow: hidden">
      <div class="info2">
        <div data-aos="fade-right">
          <p class="p3">定制</p>
          <p class="p4">专属解决方案</p>
          <div class="line"></div>
          <p class="p5">
            根据居住者特定生活方式,全屋定制与住宅规模、使用需求高匹配的系统集合,提供专属健康舒适生活解决方案,优化居住环境提升家居环境品质。
          </p>
        </div>

        <p class="p6 select">前期<br />洽谈</p>

        <p class="p7 select">概念<br />方案</p>
        <p class="p8 select">方案<br />预算</p>
        <p class="p9 select">机电<br />深化</p>
        <p class="p10 select">施工<br />落地</p>
        <p class="p11 select">完工<br />验收</p>
      </div>
    </div>
    <!-- 深化能力 -->
    <div class="box3" style="overflow: hidden">
      <div class="info3" data-aos="fade-left">
        <p class="p12">专业设计</p>
        <p class="p13">深化能力</p>
        <div class="line2"></div>
      </div>

      <div class="info3-left">
        <!-- <div class="left1">
          <div class="title3-1">N对1专业护航</div>
          <div class="title3-2">
            根据居住者特定生活方式，全屋定制与住宅规模、使用需求高匹配的系统集合，提供专届健康舒适生活解决方案，优化居住环境提升家居环境品质。
          </div>
        </div>
        <div class="left2">
          <div class="title3-1">提前规避隐患</div>
          <div class="title3-2">
            <div><span class="i1">BIM模型</span>机电管线可视化</div>
            设计前期,建立机电BIM模型，将水电管线、预埋设备、开关面板、网络线路等一览无余,以三维方式直观显示出来，防止线路碰撞和设备遗漏。
          </div>
        </div> -->
        <div class="left1">
          <img
            class="l1"
            style="display: block; width: 76%"
            src="../assets/image/technicalAdvantages/image5.png"
          />
        </div>
        <div class="left2">
          <img
            class="l2"
            style="display: block; width: 76%"
            src="../assets/image/technicalAdvantages/image6.png"
          />
        </div>
      </div>
    </div>
    <!-- 视频 -->
    <div class="box4">
      <div class="about">
        <div v-if="videoUrl">
          <video
            style="width: 100%"
            class="v1"
            :src="videoUrl"
            controls="controls"
            id="upvideo"
            :poster="imgsrc"
          >
            您的浏览器不支持视频播放
          </video>
        </div>
        <canvas id="mycanvas" style="display: none"></canvas>
      </div>
    </div>
    <!-- 施工落地 -->
    <div class="box5">
      <div class="info5">
        <p class="tit5">施工落地</p>
        <p class="tit6">管理能力</p>
      </div>

      <div class="info5bt">
        <div class="btleft">
          <img
            class="img1"
            style="display: block"
            src="../assets/image/technicalAdvantages/image11.png"
          />
          <div class="bt2">
            <div class="tit7">开工前交底</div>
            <div class="tit8">
              设计前期，建立机电BIM模型，将水电管线、预埋设备、开关面板、网络线路，以及施工节点与室内设计师交底。
            </div>
          </div>
          <img
            class="img1"
            style="display: block; margin-top: 78px"
            src="../assets/image/technicalAdvantages/image13.png"
          />
          <div class="bt2">
            <div class="tit7">施工安全保障</div>
            <div class="tit8-1">
              为强化施工过程中的安全管控，项目部人员全程跟踪检修现场，每日做好现场安全提醒,全过程安全监管。
            </div>
          </div>
        </div>
        <div class="btright">
          <img
            class="img1"
            style="display: block"
            src="../assets/image/technicalAdvantages/image12.png"
          />
          <div class="bt3">
            <div class="tit7">施工进度策划</div>
            <div class="tit8-1">
              精细化管理，进场前的质量把关，测试;过程中，严格实施施工标准工艺，遵守工期约定,各环节质量把控设备进场及安装，提前下单，按时进场。
            </div>
          </div>
          <img
            class="img1"
            style="display: block; margin-top: 75px"
            src="../assets/image/technicalAdvantages/image14.png"
          />
          <div class="bt2">
            <div class="tit7">施工交付标准</div>
            <div class="tit8-1">
              比如设备调试运行正常，各项指标要素达标，使用方式达标。
            </div>
          </div>
        </div>
      </div>
      <img
        class="bg1"
        style="display: block"
        src="../assets/image/technicalAdvantages/image3.png"
      />
      <img
        class="bg2"
        style="display: block"
        src="../assets/image/technicalAdvantages/image15.png"
      />
      <div class="bt4">
        <div class="tit9">技术保障</div>
        <div class="tit9-1">
          在征得业主同意的前提下，我们会在施工现场安装上视频监管系统。<br />
          业主无论在哪里，皆可使用电脑、平板或手机，便捷化实时监管现场施工情况。现场施工日志亦可以视频方式记录留存，以便售后维护。
        </div>
      </div>
      <img
        class="bg3"
        style="display: block"
        src="../assets/image/technicalAdvantages/image7.jpeg"
      />
      <img
        class="bg4"
        style="display: block"
        src="../assets/image/technicalAdvantages/image16.png"
      />
    </div>
    <footPage />
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
export default {
  data() {
    return {
      videoUrl: require("../assets/image/technicalAdvantages/BIM.mp4"),
      imgsrc: "",
    };
  },
  mounted() {
    this.findvideocover(this.videoUrl, { url: "" });
  },
  methods: {
    jump(url) {
      this.$router.push(url);
    },
    findvideocover(url, file) {
      const video = document.getElementById("upvideo"); // 获取视频对象
      // const video = document.createElement("video") // 也可以自己创建video
      video.src = url; // url地址 url跟 视频流是一样的
      var canvas = document.getElementById("mycanvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧
      video.oncanplay = () => {
        canvas.width = video.clientWidth; // 获取视频宽度
        canvas.height = video.clientHeight; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
        // 转换成base64形式
        this.imgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        file.url = this.imgsrc;
      };
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss" scoped>
.select:hover {
  transform: scale(1.4);
  // box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.l1:hover {
  transform: scale(1.2);
  // box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.l2:hover {
  transform: scale(1.2);
  // box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.img1:hover {
  transform: scale(1.1);
  // box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.left1 {
  margin-right: -86px;
}
.btleft {
  width: 582px;
  height: 931px;
}
.btright {
  width: 582px;
  height: 931px;
  margin-left: -29px;
  margin-top: 0;
}
.bt2 {
  margin-left: 31px;
  margin-top: 9px;
}

.info5bt {
  margin-top: 49px;
  margin-left: 218px;
  overflow: hidden;
  text-align: left;
  display: flex;
  position: relative;
  z-index: 999;
}
.tit7 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 29px;
  margin-top: -11px;
  color: #000;
  border-left: 14px solid #d3b968;
  line-height: 26px;
  padding-left: 14px;
}
.tit9 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 29px;
  margin-top: -11px;
  color: #000;
  border-left: 14px solid #d3b968;
  line-height: 26px;
  padding-left: 14px;
}
.tit8 {
  font-family: "SourceHanSansCN-Light";
  font-size: 12px;
  color: #8e8c8d;
  width: 473px;
  margin-top: 16px;
}
.tit8-1 {
  width: 470px;
  font-family: "SourceHanSansCN-Light";
  font-size: 12px;
  color: #8e8c8d;
  margin-top: 16px;
}
.tit9-1 {
  width: 429px;
  font-family: "SourceHanSansCN-Light";
  font-size: 12px;
  color: #8e8c8d;
  margin-top: 33px;
}
.img1 {
  width: 528px;
}
.bg1 {
  width: 100%;
  transform: translate(0px, -351px);
  z-index: -1;
}
.bg2 {
  width: 322px;
  margin-left: 20px;
  margin-top: -731px;
  position: relative;
}
.bg3 {
  display: block;
  width: 490px;
  margin-left: 781px;
  margin-top: -211px;
  position: relative;
}
.bg4 {
  display: block;
  width: 129px;
  margin-left: 77%;
  margin-top: -176px;
  position: relative;
}

.i1 {
  color: #d3b968;
}
.info3-left {
  display: flex;
  text-align: left;
  margin-left: 222px;
  margin-top: -293px;
  width: 985px;
}
.title3-1 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 22px;
  color: #000;
}
.title3-2 {
  font-family: "SourceHanSansCN-Light";
  color: #8e8c8d;
  width: 230px;
  margin-top: 33px;
}
.info {
  margin-top: 183px;
}
.info2 {
  text-align: left;
  margin-top: 181px;
  margin-left: 247px;
}
.p1 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 63px;
  color: #d3b968;
}
.p2 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 62px;
  color: #fff;
  margin-top: -13px;
}
.p3 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 50px;
  font-weight: 100;
}
.p4 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 51px;
  margin-top: -9px;
  color: #d3b968;
}
.p5 {
  font-family: "SourceHanSansCN-Light";
  color: #8e8c8d;
  width: 372px;
  margin-top: 25px;
  text-align: justify;
}
.p6 {
  font-family: "SourceHanSansCN-Medium";
  width: 69px;
  height: 69px;
  font-size: 18px;
  position: absolute;
  box-shadow: -1px 0 13px 0 rgba(222, 222, 127, 5%);
  top: 115px;
  left: 64.8%;
  color: #000;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  background-color: #fff;
}
.p7 {
  font-family: "SourceHanSansCN-Medium";
  width: 69px;
  height: 69px;
  box-shadow: -1px 0 13px 0 rgba(222, 222, 127, 5%);
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  font-size: 18px;
  position: absolute;
  top: 186px;
  left: 73.8%;
  color: #000;
  background-color: #fff;
}
.p8 {
  font-family: "SourceHanSansCN-Medium";
  width: 69px;
  height: 69px;
  box-shadow: -1px 0 13px 0 rgba(222, 222, 127, 5%);
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  font-size: 18px;
  position: absolute;
  top: 374px;
  left: 73.8%;
  color: #000;
  background-color: #fff;
}
.p9 {
  font-family: "SourceHanSansCN-Medium";
  width: 69px;
  height: 69px;
  box-shadow: -1px 0 13px 0 rgba(222, 222, 127, 5%);
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  font-size: 18px;
  position: absolute;
  top: 449px;
  left: 64.8%;
  color: #000;
  background-color: #fff;
}
.p10 {
  font-family: "SourceHanSansCN-Medium";
  width: 69px;
  height: 69px;
  box-shadow: -1px 0 13px 0 rgba(222, 222, 127, 5%);
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  font-size: 18px;
  position: absolute;
  top: 375px;
  left: 55.4%;
  color: #000;
  background-color: #fff;
}
.p11 {
  font-family: "SourceHanSansCN-Medium";
  width: 69px;
  height: 69px;
  box-shadow: -1px 0 13px 0 rgba(222, 222, 127, 5%);
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  font-size: 18px;
  position: absolute;
  top: 186px;
  left: 55.4%;
  color: #000;
  font-weight: 100;
  background-color: #fff;
}
.info3 {
  text-align: right;
  margin-top: 204px;
  margin-right: 277px;
}
.p12 {
  font-size: 51px;
  font-family: "SourceHanSansCN-Light";
  color: #000;
  font-weight: 300;
}
.p13 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 51px;
  margin-top: -9px;
  color: #d3b968;
}
.info5 {
  text-align: left;
  margin-left: 246px;
  margin-top: 94px;
}
.tit5 {
  font-size: 51px;
  font-family: "SourceHanSansCN-Light";
  color: #000;
  font-weight: 300;
}
.tit6 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 51px;
  margin-top: -9px;
  color: #d3b968;
}
.line {
  height: 2px;
  margin-top: 29px;
  width: 46px;
  background-color: #000;
}
.line2 {
  height: 2px;
  margin-top: 31px;
  width: 46px;
  background-color: #000;
  margin-left: 1190px;
}
.box1 {
  background-image: url(../assets/image/technicalAdvantages/image2.png);
  background-size: cover;
  width: 100%;
  height: 621px;
}
.box2 {
  position: relative;
  background-image: url(../assets/image/technicalAdvantages/image1-1.png);
  background-size: cover;
  width: 100%;
  height: 634px;
}
.box3 {
  position: relative;
  background-image: url(../assets/image/technicalAdvantages/image4.png);
  background-size: cover;
  width: 100%;
  height: 634px;
}
.box4 {
  width: 100%;
  height: 640px;
}
.box5 {
  width: 100%;
  height: 1599px;
  position: relative;
}
.bt4 {
  margin-top: -496px;
  margin-left: 246px;
  z-index: 999;
  position: relative;
  text-align: left;
}
.bt3 {
  margin-left: 31px;
  margin-top: 9px;
}
</style>